import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter11 from './../../assets/chapter11.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>As enormes canas verdes que estavam espalhadas por todo 
o reino, começaram a florescer e dentro delas saíram mais 
Quebra-nozes que ajudaram a prender a Fada dos Doces


</p>
                    <p>O Quebra-nozes de Clara voltou a recuperar o seu braço e a 
segurar uma escova Babu. A Bailarina começou a dançar. A 
melodia da música espalhou-se por todo o reino e recuperou 
tudo o que estava danificado, incluindo a Fada dos Doces 
que se transformou na Fada dos Dentes.


</p>
                    <p>Os ratos choraram de alegria ao verem a Fada dos Dentes, 
que correu para os abraçar e juntos, continuarem, em paz, a 
fabricar as escovas de Bambu.


</p>
                    <p> Este reino é lindo – disse Clara maravilhada.</p>
<p>- Agora é seu, Rainha Clara! - disse a Bailarina enquanto 
fazia uma vénia.</p><p>
- Mas eu não posso ficar – afirmou Clara.


</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter10"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter12"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter11} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
