import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter8 from './../../assets/chapter8.png'





class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Una fuerte luz ciega brevemente al Cascanueces hasta que 
grita:
</p>
                    <p>- ¡Clara eres la princesa! ¡Este es el Reino Babú y yo soy el 
último Cascanueces!
</p>
                    <p>Clara lo mira confusa hasta que aparecen su Bailarina y los 
ratones para salvarlos.
</p>
                    <p>- Apúrate querida, tenemos que protegerte del Hada de los 
Caramelos. - dijo la Bailarina mientras agarraba la mano de 
Clara.

</p>
                    <p>- ¡Cascanueces! - gritó Clara, mientras se apartaba de su fiel 
amigo, que luchaba contra el enorme ejército de gomitas 
junto a los ratones.

</p>
                    <p>En un lugar, seguro y cálido, la Bailarina explicó a Clara su 
verdadera historia. El verdadero nombre del Reino Verde 
era el Reino Babú y había sido creado por sus antepasados 
con el objetivo de cuidar de la felicidad de las personas 
y del planeta Tierra a través de la planta de más rápido 
crecimiento del mundo, el bambú.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter7"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter9"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter8} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
