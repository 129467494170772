import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter10 from './../../assets/chapter10.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Todos los Cascanueces y también la madre de Clara fueron 
eliminados por el Hada de los Caramelos, que había buscado 
a la princesa todos estos años. El Hada de los Caramelos 
también consiguió hechizarnos alterando parte de nuestra 
memoria”.

</p>
                    <p>- Pero, ¿cómo puedo salvar el Reino Babu? - preguntó Clara.
</p>
                    <p>- Todo lo que necesitas está dentro de ti. ¡Tienes un poder 
único! - respondió la bailarina con voz dulce.

</p>
                    <p>Clara tuvo una idea. Pidió a los ratones que la ayudasen 
a construir una enorme caja amarilla. Mientras tanto, el 
Cascanueces atrajo al ejército de gominolas, con bastones 
de plástico, hasta la caja amarilla. Cuando el ejército pasó 
por la caja amarilla, las gomas se convirtieron en nubes y el 
plástico también tomó una nueva vida.

</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter9"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter11"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter10} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
