import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter5 from './../../assets/chapter5.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Cuando llegaron al palacio del Hada de los Caramelos 
fueron parados por los soldados de la goma de mascar.

</p>
                    <p>- ¡Bueno, bueno, bueno! ¿A quién tenemos aquí? El último 
Cascanueces con una niña... - dijo uno de los soldados.
</p>
                    <p>“¿El último Cascanueces?” pensaron Clara y Cascanueces.
</p>
                    <p>- ¿Qué quieres de aquí? - interrumpió el segundo soldado.

</p>
                    <p>Al ver la mirada triste y confusa de Cascanueces, Clara 
decidió retomar la conversación.
</p>
                    <p> ¡Hemos venido a pedir ayuda al Hada de los Caramelos! 
Los ratones han robado mi bailarina y el Cascanueces cree 
que ella podría ser la princesa de este reino. - dijo Clara
</p>
                    <p>Los dos soldados los miraron y empezaron a reírse a 
carcajadas hasta que una sombra llegó y los dejó sin la luz 
del sol. Al ver la sombra, los soldados dejaron de reír y se 
colocaron inmediatamente en posición de guardia.
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter4"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter6"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter5} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
