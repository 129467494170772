import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter3 from './../../assets/chapter3.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>A huge noise woke Clara up!</p>
                    <p>Opening her eyes, Clara was startled by the army of rats that 
were walking towards her ballerina. They were all carrying a 
bamboo toothbrush.<br/>
Suddenly a tall and brave soldier appeared and tried to stop 
the rats from taking the ballerina, but with no success.</p>
                    <p>- Nutcracker? - asked Clara, surprised - “Give me back my 
ballerina!” - she shouted in the direction of the rats.</p>
                    <p>- I’ll save you! - shouted the Nutcracker as he ran towards 
the hole where the rats had escaped.</p>
                    <p>Clara jumped out of bed and ran after them, and it was at 
that moment that she realised she had shrunk.</p>
                    <p>As she went through the hole, Clara came to a beautiful 
place full of green reeds.</p>

                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter2"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter4"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter3} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
