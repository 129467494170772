import React, { Component } from 'react'
import $ from "jquery";
import {Link} from 'react-router-dom'


//animation
import Fade from 'react-reveal';


import 'react-multi-carousel/lib/styles.css';




/* images */
import fotoproduct from './../../assets/produtos/image1.png'
import fantoches from './../../assets/produtos/image2.png'
import certs from './../../assets/produtos/certp.jpg'
import estadio from './../../assets/bg1.png'
import pggh from './../../assets/pggh.jpg'
import leftBrush from './../../assets/produtos/leftbrush.png'
import rightBrush from './../../assets/produtos/rightbrush.png'
import featureImg from './../../assets/produtos/img1en.png'
import certifi from './../../assets/certificados.jpg'

/*imagens produtos */

import produto1 from './../../assets/produtos/pd-qb.png'
import produto2 from './../../assets/produtos/pd-ps.png'
import produto3 from './../../assets/produtos/pd-bl.png'
import produto4 from './../../assets/produtos/pd-rt.png'



class Home extends Component {
    constructor(){
        super()
        this.state={
            produtos:[
                {name:"Nutcracker", img:produto1, link:"https://shop.babueco.com/en/produto/nutcracker-princess/?attribute_pa_escolhe-o-teu-aliado=quebra-nozes-en"},
                {name:"Princess", img:produto2, link:"https://shop.babueco.com/en/produto/nutcracker-princess/?attribute_pa_escolhe-o-teu-aliado=princesa-en"},
                {name:"Ballerina", img:produto3, link:"https://shop.babueco.com/en/produto/ballerina-little-rat/?attribute_pa_aliado-kids=bailarina-en"},
                {name:"Little rat", img:produto4, link:"https://shop.babueco.com/en/produto/ballerina-little-rat/?attribute_pa_aliado-kids=ratinho-en"},
            ],
        }
        this.handleForm = this.handleForm.bind(this)
        this.openForm = this.openForm.bind(this)
        this.handleAddition = this.handleAddition.bind(this)
    }

    componentDidMount() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }

    openForm(){
        this.setState({
            formactivation:!this.state.formactivation
        })
    }
    handleForm(e){
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: '',
        formactivation:!this.state.formactivation
        });
    
        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "newsletter.php",
        type: 'POST',
        data: {
            'email': this.state.email,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Foi adicionado à nossa lista!</h2></div>'
            });
            $('#containerText').slideUp();
            $('#containerText').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@babueco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
    }

    handleAddition(e){
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

  render() {
//  style for first sec // style={{background: `url(${bgImg}) no-repeat center center`, backgroundSize:`cover`}}
    return (
        <main className="home_babu">
            
            <header id="home" className="hero masthead" style={{background: `url(${estadio}) no-repeat center center`,backgroundSize:`cover`}}>
                <div className="cta__ hero__container">
                    <Fade forever left >
                        <div className="cta__image">
                            <img src={featureImg} alt="" />
                        </div>
                    </Fade>
                    <Fade forever right>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>This Christmas everything you need is inside you!</h2>
                                <div className="buttons-row">
                                    <a href="#produtos"><button className="button__babu">Buy now</button></a>
                                    <Link to="/story/chapter1"><button className="button__babu">The Story</button></Link>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
               
            </header>
            <section id="quebraNozes" className="hero firstsec">
                <Fade forever left>
                    <div className="left-brush">
                        <img src={leftBrush} alt=""/>
                    </div>
                </Fade>
                <div className="principal">
                    <Fade forever >
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <div className="natal-title">
                                    <h2>The Nutcracker</h2>
                                    <h4 style={{color:"#bed600"}}>The Babu Kingdom</h4>
                                </div>
                                <p>
                                    Discover the story of The Nutcracker and Princess Clara. And share it with your loved ones this Christmas.<br/>
                                    <span style={{color:'#999',fontSize:'12px'}}>This collection is limited to the existing stock.</span>
                                </p>
                                
                                <Link to="/story/chapter1"><button className="button__babu">Read the story</button></Link>
                            </div>
                        </div>
                    </Fade>
                </div>
                 <Fade forever right>
                    <div className="right-brush">
                        <img src={rightBrush} alt=""/>
                    </div>
                </Fade>
            </section>
            <section id="produtos" className="itens-section">
                <Fade forever delay={100}>
                    <div className="natal-title title">
                        <h2>THE REAL HERO IS YOU</h2>
                        <h4>CHOOSE YOUR ALLY</h4>
                    </div>
                </Fade>
                <div className="item-list">
                    <Fade forever delay={300} cascade>
                        {this.state.produtos.map((item, index)=>{
                                return(
                                    <div  key={index + "item"} className="items_container">
                                        <div className="items_content">
                                            <div className="item">
                                                <a target="_blank" href={item.link} >
                                                    <img src={item.img} alt=""/>
                                                    <div className="item_link">{item.name}</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Fade>
                </div>
            </section>
            <section id="porque" className="hero buysec">
                <div className="cta__">
                    <Fade forever bottom delay={50}>
                        <div className="cta__image">
                            <img src={fotoproduct} alt="" />
                        </div>
                    </Fade>
                    <Fade forever bottom delay={200}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Pack for the whole family</h2>
                                <p>Some stories deserve to be shared with the whole family. That's why we've created a pack full of love.</p>
                                <div className="buttons-row">
                                    <a href="https://shop.babueco.com/en/produto/family-pack-nutcracker-edition/"><button className="button__babu">Buy now</button></a>
                                    <Link to="/story/chapter1"><button className="button__babu">The Story</button></Link>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <section id="embalagens" className="hero buysec">
                <div className="cta__">
                    <Fade forever bottom delay={200}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Use the packaging as puppets</h2>
                                <p>Creativity! You can reuse the packaging and turn them into little puppets and recreate the story of the Nutcracker or even create your own story.</p>
                            </div>
                            <img style={{maxWidth:'300px'}} src={certifi} alt=""/>
                        </div>
                    </Fade>
                    <Fade forever bottom delay={50}>
                        <div className="cta__image fantoche">
                            <img src={fantoches} alt="" />
                        </div>
                    </Fade>
                </div>
            </section>
           {/*  <section id="certificados" className="hero certs">
                <div className="cta__">
                    <Fade forever bottom delay={100}>
                        <div className="cta__image">
                            <img src={certs} alt="" />
                        </div>
                    </Fade>
                    <Fade forever bottom delay={50}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Certificações que valem golos</h2>
                                <p style={{marginBottom:"10px"}}>
                                    Todos os produtos da Babu são certificados <em style={{fontStyle:'italic', fontWeight:"bold"}}>Vegan</em> pela <em style={{fontStyle:'italic', fontWeight:"bold"}}>Vegan Society</em> e <em style={{fontStyle:'italic', fontWeight:"bold"}}>Cruelty-free</em> pela PETA.org.
                                </p>
                                <p>
                                    Os parceiros de produção são certificados pela <strong>amfori BSCI</strong> por cumprirem critérios de satisfação do trabalho,
                                    bem como por várias outras entidades de certificação de qualidade e sustentabilidade,
                                    tais como:
                                    <ul className="list-cert">
                                        <li><strong>Certificado de Conformidade de RoHS</strong></li>
                                        <li><strong>Certificação FSC de Cadeia de Custódia</strong></li>
                                        <li><strong>Certificação de Sistemas de Gestão da Qualidade ISO 9001</strong></li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </Fade>

                </div>
            </section>*/}

            <section id="babu" className="hero redbackground">
                <div className="blockup"></div>
                <div className="cta__">
                <Fade forever delay={300}><div className="cta__image"> <img className="diagonal--inverted" src={pggh} alt=""/></div></Fade>
                    <Fade forever delay={1000}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Small gestures, big heroes!</h2>
                                <p>
                                    Babu is a brand born from the belief that we can and should create ecological solutions for the current needs of us all,
                                    thus contributing to the change we all desire.
                                </p>
                                <p>
                                    Babu's mission goes far beyond producing its signature products.
                                    By choosing Babu, you are not only making an ecological choice, you are also contributing to all the causes the brand supports, such as environmental preservation
                                    conservation and raising awareness of better habits among the younger generations.
                                </p>
                                <div className="buttons-row">
                                    <a href="https://babueco.com/products"><button className="button__babu">Babu's Products</button></a>
                                    <a href="https://babueco.com/about"><button className="button__babu">Babu</button></a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="blockdown"></div>

            </section>
            <section className="hero subscription">
                <Fade forever left>
                    <div className="cta__--white">
                        <div className="cta__containertext">
                            <div className="container__text" id="containerText">
                                <h2>
                                    Do you already know our newsletter?
                                </h2>
                                <form className="news__sub" action="newsletter.php" onSubmit={this.handleForm}>
                                    <input type="email" name="email" onChange={this.handleAddition} placeholder="" required/>
                                    <div className="privacy_sub"><input type="checkbox" name="privacy" onChange={this.handleAddition} required/><p><span>I've read and understand Babu's <a href="http://shop.babueco.com/en/privacy-policy/">Privacy Policy</a></span></p></div>
                                    <button onSubmit={()=>this.handleForm()} className="button__babu">Enviar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
      </main>
    )
  }
}

export default Home

