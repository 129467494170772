import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter11 from './../../assets/chapter11.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>The huge green reeds that were scattered all over the 
kingdom, started to bloom and out of them came more 
Nutcrackers that helped trap the Candy Fairy.


</p>
                    <p>Clara’s Nutcracker regained his arm and held a Babu 
toothbrush. The Ballerina began to dance. The melody of 
the song spread throughout the kingdom and recovered 
everything that was damaged, including the Candy Fairy 
who turned into the Tooth Fairy.


</p>
                    <p>All the mouses cried with joy when they saw the Tooth Fairy, 
who ran to hug them and together they continued, in peace, 
to make their toothbrushes out of bamboo.


</p>
                    <p>- This kingdom is beautiful. - said Clara in wonder.</p>
<p>- It is yours now, Queen Clara! - said the ballerina as she 
bowed.</p><p>
- But I cannot stay - said Clara.

</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter10"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter12"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter11} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
