import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter6 from './../../assets/chapter6.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Quel est votre nom ? - dit une belle femme avec d’énormes 
ailes en atterrissant devant Clara
</p>
                    <p>- Moi ? Je suis Clara ! - dit-elle confuse.
</p>
                    <p>Casse-Noisette s’incline rapidement devant la fée des 
bonbons et lui dit qu’il doit lui parler car il a des informations 
qui l’intéressent. La fée des bonbons ignore CasseNoisette et continue de parler à Clara, tout en la regardant 
mystérieusement.
</p>
                    <p>- Clara, ma chère, est-ce votre seul nom ? Que faites-vous 
dans le Royaume Vert ?
</p>
                    <p>- Non, je suis Clara Babu et je suis venue chercher ma 
ballerine avec le... - Clara est rapidement interrompue par la 
Fée des Bonbons qui les invite dans son palais.
</p>
                    <p>Alors qu’ils entrent, Casse-Noisette est silencieux et pensif 
en regardant Clara, car son nom ne lui est pas étranger. La 
Fée des bonbons les invite à s’asseoir et leur sert un énorme 
goûter tout en regardant Casse-Noisette.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter5"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter7"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter6} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
