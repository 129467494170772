import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

//import ilustration from './../../assets/'

import chapter1 from './../../assets/chapter1.png'
import letterE from './../../assets/t.png'



class Story extends Component {
    constructor(){
        super()
        this.state={
            
        }

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_title'>
                    <h1>The Nutcracker</h1>
                    <h2>The Babu Kingdom</h2>
                </div>
                <div className='story_pg'>
                    <div className='firstparagraph'>
                        <img src={letterE} alt=""/>
                        <p className='firstp'>
was Christmas Eve and whilst the snow covered the city in a white blanket,
 a sweet child was waiting for her beloved Godfather. Clara was an orphan who lived 
with other children in an old orphanage and every year 
they received a visit from their Godparents with Christmas 
presents.</p>
                    </div>
                    <p>When the bell rang, all the children ran to the door hoping 
it was their Godfather, but it was Clara’s Godfather who had 
arrived, with a small box wrapped in red paper and a green 
bow. Clara smiled and quickly unwrapped the present. It was 
a beautiful Nutcracker!</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter2"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter1} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
