import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

import chapter12 from './../../assets/chapter12.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Tout le monde semblait confus et triste, jusqu’à ce que le 
Casse-Noisette apparaisse.
</p>
                    <p>- Nous savons! Ton pouvoir peut atteindre des millions de 
personnes. Tout ce que tu as à faire c’est de le partager. - 
sourit le Casse-Noisette.
</p>
                    <p>- Sur la planète Terre, tout le monde peut être un héros, je 
dois juste montrer aux gens comment ils peuvent le faire. - 
dit Clara, avec de l’espoir dans la voix.
</p>
                    <p>Clara demanda à la Ballerine de diriger le royaume en son 
absence, et Casse-Noisette lui proposa de l’aider dans sa 
mission sur la planète Terre.
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter11"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter13"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter12} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
