import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import chapter9 from './../../assets/chapter9.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>The Babu Kingdom was once ruled by Clara’s mother. 
She used the bamboo, to create her two greatest allies: 
the Ballerina and the Nutcracker. When the Candy Fairy 
appeared with her jelly beans soldiers, she created an 
army of Nutcrackers to defend the kingdom. But... it wasn’t 
enough.
</p>
                    <p>The little mouse, who were peaceful animals and who carved 
bamboo brushes with their teeth, decided to form an army to 
defend the kingdom.
</p>
                    <p>- ”So, am I the princess of this kingdom? - asked Clara.
</p>
                    <p>- No, my love, you are Queen Clara,” affirmed the ballerina 
with a smile as she ran her delicate hand over Clara’s face. 
And she continued to tell the story
</p>
                    <p>“When the mouses realised that the Queen, Clara’s mother, 
was very ill and the Nutcrackers were weaker, they suggested 
a plan, run away with Princess Clara, take the Ballerina and 
the Nutcracker to protect her, until she was grown up enough
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter8"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter10"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter9} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
