import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter5 from './../../assets/chapter5.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Lorsqu’elles atteignirent le palais de la fée des bonbons, 
elles furent arrêtées par les soldats de la gomme.

</p>
                    <p>-  Eh bien ! Qui avons-nous là ? Le dernier Casse-Noisette 
avec une petite fille... - dit un des soldats
</p>
                    <p> Le dernier Casse-Noisette ?” pensèrent Clara et Casse-Noisette.

</p>
                    <p>- Qu’est ce que vous voulez ici ? - a interrompu le deuxième 
soldat.
</p>
                    <p>Voyant le regard triste et confus de Casse-Noisette, Clara 
décide de prendre le relais de la conversation.
</p>
                    <p>- Nous sommes venus demander de l’aide à la fée des 
bonbons ! Les rats ont volé ma ballerine et Casse-Noisette 
pense qu’elle pourrait être la princesse de ce royaume. - dit 
Clara.
</p>
                    <p>Les deux soldats les regardèrent et commencèrent à rire 
aux éclats jusqu’à ce qu’une ombre vienne les priver de la 
lumière du soleil. En voyant l’ombre, les soldats cessèrent 
de rire et se mirent immédiatement au garde-à-vous.
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter4"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter6"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter5} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
