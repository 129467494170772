import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter2 from './../../assets/chapter2.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Curiosas e entusiasmadas, todas as crianças correram 
eufóricas para junto de Clara, agarrando violentamente o 
Quebra-nozes. Clara gritou para terem cuidado, mas era 
tarde de mais, o braço de Quebra-nozes estava partido.</p>
                    <p>Ao ver o seu único presente de Natal destruído, Clara 
agarrou-o e a chorar correu rapidamente para o quarto. O 
quarto de Clara era partilhado com mais 10 meninas e a sua 
cama ficava ao lado de uma janela grande e gelada. Ao 
chegar ao quarto Clara pousou o Quebra-nozes em cima da 
cama e abriu uma caixa de música, que se encontrava na 
sua mesa de cabeceira.</p>
                    <p>Uma suave melodia começou a tocar, enquanto uma 
bailarina vestida de cor de rosa rodopiava dentro da caixa. 
Esta caixinha de música era muito especial para Clara, foi o 
último presente que a mãe lhe deu antes de morrer. Ao ouvir 
a melodia e com os olhos mergulhados em lagrimas Clara 
deitou-se junto do seu Quebra-nozes e adormeceu.</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter1"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter3"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter2} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
