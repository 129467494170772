import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter6 from './../../assets/chapter6.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- ¿Cómo os llamáis? - dijo una hermosa mujer con enormes 
alas al aterrizar frente a Clara.
</p>
                    <p>- ¿Yo? ¡Yo soy Clara! - dijo ella desconcertada.
</p>
                    <p>El Cascanueces se inclinó rápidamente delante del Hada 
de los Caramelos y le dijo que tenía que hablar con ella 
pues guardaba informaciones de su interés. El Hada de los 
Caramelos ignoró a Cascanueces y siguió hablando con 
Clara, mientras la miraba misteriosamente.
</p>
                    <p>- Clara, querida, ¿es ese tu único nombre? ¿Qué haces en el 
Reino Verde?
</p>
                    <p>- No, soy Clara Babu y he venido a recoger a mi bailarina 
con... - Clara es rápidamente interrumpida por el Hada de 
los Caramelos que les invita a entrar en su palacio
</p>
                    <p>Al entrar, Cascanueces se quedó en silencio y pensativo 
mirando a Clara, pues su nombre no le era extraño. El Hada 
de los Caramelos les invitó a sentarse y les sirvió una enorme 
merienda mientras miraba a Cascanueces.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter5"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter7"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter6} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
