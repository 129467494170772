import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import chapter9 from './../../assets/chapter9.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>El Reino Babu fue gobernado por la madre de Clara. Ella utilizó 
el bambú para crear a sus dos mayores aliados: la Bailarina y 
el Cascanueces. Cuando el Hada de los Caramelos apareció 
con sus soldados Goma, creó un ejército de Cascanueces 
para defender el reino. Pero... no fue suficiente.
</p>
                    <p>Los ratoncitos, que eran animales pacíficos y que esculpían 
cepillos de bambú con los dientes, resolvieron formar un 
ejército para defender el reino.
</p>
                    <p>- “Entonces, ¿soy la princesa de este reino? - preguntó Clara.
</p>
                    <p>- No, mi amor, tú eres la reina Clara”, afirmó la bailarina con 
una sonrisa mientras pasaba su delicada mano por la cara 
de Clara. Y continuó contando la historia.
</p>
                    <p>“Cuando los ratones se dieron cuenta de que la Reina, la 
madre de Clara, estaba muy enferma y los Cascanueces eran 
más débiles, propusieron un plan, escaparse con la princesa 
Clara, coger a la Bailarina y al Cascanueces para protegerla, 
hasta que creciera lo suficiente para salvar el reino.
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter8"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter10"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter9} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
