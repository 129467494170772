import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter7 from './../../assets/chapter7.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Merci Casse-Noisette d’être venu me voir. J’ai besoin de 
ton aide pour arrêter cette terrible armée ! Tout le monde 
dans le royaume a peur d’eux. Et je pense que la Ballerine, 
dans de mauvaises mains pourrait détruire cet endroit 
merveilleux. N’es-tu pas d’accord, petite Clara ? - demande 
la Fée des Bonbons.
</p>
                    <p>Clara et Casse-Noisette ont accepté d’aider la Fée des 
bonbons et de sauver la Ballerine. La fée des bonbons leur 
donna une armée de gommes toutes armées de bâtons en 
plastique afin d’abattre l’armée de rats.
</p>
                    <p>Casse-Noisette et Clara marchent vers les rats avec l’armée 
de la Fée aux bonbons jusqu’à ce qu’ils entendent un bruit 
parmi les énormes roseaux verts. Il s’agit de l’armée de la 
Fée aux bonbons, mais quelque chose ne va pas car ils 
commencent à les entourer d’un regard féroce et menaçant.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter6"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter8"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter7} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
