import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter4 from './../../assets/chapter4.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Señorita Clara, debería haberse quedado en su habitación. 
Este reino es muy peligroso. - dijo el Cascanueces con voz 
autoritaria.
</p>
                    <p>- ¡Se han llevado a mi bailarina y no entiendo por qué! 
Además, tienes el brazo roto y puedo ayudarte. - dijo la 
pequeña Clara.
</p>
                    <p>Cascanueces se miró el brazo de forma triste y dijo:
</p>
                    <p>- ¡Tenemos que pedirle ayuda al Hada de los Caramelos! Te 
contaré la historia por el camino
</p>
                    <p>Mientras caminaban por el bosque lleno de juncos verdes, 
el Cascanueces le contó a Clara la historia del Reino Verde. 
Hace muchos años, el rey de los ratones convirtió a la 
princesa del reino en una bailarina. El Hada de los Caramelos 
intentó salvarla, pero sin éxito, porque los ratones utilizaron 
un arma peligrosa: ¡el cepillo de dientes de bambú!
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter3"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter5"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter4} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
