import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import chapter9 from './../../assets/chapter9.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>O Reino Babu foi em tempos governado pela mãe de Clara. 
Ela utilizou o bambu, para criar os seus dois maiores aliados: 
a Bailarina e o Quebra-nozes. Quando a Fada dos Doces 
apareceu com os seus soldados Goma, criou um exército 
de Quebra-nozes para defender o reino. Mas... não foi o 
suficiente.
</p>
                    <p>Os ratos que eram animais pacíficos e que esculpiam 
escovas de bambu, com os seus dentes, decidiram formar 
um exército para defenderem o reino
</p>
                    <p>- Então eu sou a princesa deste reino? – perguntou Clara.
</p>
                    <p>- Não meu amor, tu és a Rainha Clara – afirmou a Bailarina 
com um sorriso enquanto passava a sua mão delicada pelo 
rosto de Clara. E continuou a contar a história.
</p>
                    <p>“Quando os ratos se aperceberam que a rainha, mãe de 
Clara, estava muito doente e que os Quebra-nozes estavam 
mais fracos, sugeriram um plano, fugir com a princesa Clara, 
levar a Bailarina e o Quebra-nozes para a protegerem, até 
ser suficientemente crescida para salvar o reino.
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter8"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter10"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter9} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
