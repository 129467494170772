import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter8 from './../../assets/chapter8.png'





class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
               <p> Une forte lumière aveugle brièvement le Casse-Noisette 
jusqu’à ce qu’il crie :</p>
<p>- Clara, tu es la princesse ! C’est le royaume de Babu et je 
suis le dernier Casse-Noisette !</p>
<p>Clara le regarde confuse jusqu’à ce que sa Ballerine et les 
rats apparaissent pour les sauver.</p>
<p>- Dépêchez-vous ma chère, nous devons vous protéger de 
la fée des bonbons. - dit la Ballerine en tenant la main de 
Clara.</p>
<p>- Casse-Noisette ! - cria Clara, en se détournant de son fidèle 
ami, qui combattait l’énorme armée de gommes aux côtés 
des rats.</p>
<p>Dans un endroit sûr et chaleureux, la Ballerine expliqua à 
Clara sa véritable histoire. Le vrai nom du Royaume Vert était 
le Royaume de Babu et il avait été créé par ses ancêtres 
dans le but de prendre soin du bonheur des gens et de la 
planète Terre grâce à la plante à la croissance la plus rapide 
du monde, le bambou.</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter7"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter9"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter8} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
