import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

import chapter12 from './../../assets/chapter12.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Everyone looked confused and sad, until the Nutcracker 
appeared.
</p>
                    <p>- We know! Your power can reach millions of people. All you 
have to do is share it. - smiled the Nutcracker.
</p>
                    <p>- On Planet Earth, everyone can be a hero, I just have to 
show people how they can do it. - said Clara, with hope in 
her voice.
</p>
                    <p>Clara asked the Ballerina to rule the kingdom in her absence, 
and the Nutcracker offered to help her in her mission around 
Planet Earth.
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter11"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter13"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter12} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
