import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter6 from './../../assets/chapter6.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>-Como te chamas? – disse uma mulher vistosa com umas 
enormes asas enquanto pousava em frente de Clara.
</p>
                    <p>- Eu?! Sou a Clara! – disse confusa.
</p>
                    <p>Quebra-nozes rapidamente fez uma vénia à Fada dos 
Doces e afirmou que precisava de falar com ela, pois tinha 
informações do seu interesse. A Fada dos Doces ignorou 
o Quebra-nozes e continuou a falar com Clara, enquanto 
olhava para ela misteriosamente.
</p>
                    <p>- Clara, querida, esse é o teu único nome? O que fazes tu no 
Reino Verde?
</p>
                    <p>-  Não, sou a Clara Babu e vim buscar a minha bailarina com 
o... – Clara rapidamente é interrompida pela Fada dos Doces 
que os convida a entrar no seu palácio.
</p>
                    <p>Enquanto entravam Quebra-nozes ia calado e pensativo a 
olhar para a Clara, pois o nome dela não lhe era estranho. 
A Fada dos Doces convidou-os a sentar e serviu-lhes um 
enorme lanche enquanto olhava para o Quebra-nozes.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter5"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter7"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter6} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
