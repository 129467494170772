import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter2 from './../../assets/chapter2.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Curiosos y emocionados, todos los niños corrieron excitados 
hasta Clara, agarrando violentamente al Cascanueces. Clara 
les gritó que tuviesen cuidado, pero era demasiado tarde, el 
brazo del Cascanueces se había roto.</p>
                    <p>Viendo su único regalo de Navidad destrozado, Clara lo 
cogió y entre lágrimas corrió rápidamente a su habitación. 
La habitación de Clara era compartida con otras 10 niñas y 
su cama estaba junto a una gran ventana helada. Al llegar a 
su habitación, Clara dejó el Cascanueces en la cama y abrió 
una caja de música que estaba en su mesita de noche.</p>
                    <p>Una suave melodía comenzó a sonar, mientras una hermosa 
bailarina rosa giraba dentro de la caja. Esta caja de música 
era muy especial para Clara, fue el último regalo que le 
ofreció su madre antes de morir. Al escuchar la melodía y 
con los ojos inundados de lágrimas, Clara se acostó junto a 
su Cascanueces y se quedó dormida.</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter1"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter3"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter2} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
