import React, { Component } from 'react'

import smf from './../../assets/icons/smf.png'
import smi from './../../assets/icons/smi.png'
import smy from './../../assets/icons/smy.png'


class Footer extends Component {
  render() {
    return (
    <footer className="footer">
          <div className="footer__container">
            <div className="terms">
              <div className="both">
                  <a href="https://babueco.com/perguntasfrequentes">Questions fréquentes</a>
                  <a href="https://babueco.com/contactos">Contact</a>
              </div>
            </div>
              <ul className="socialmedia">
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/BabuEco/"><img src={smf} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/babueco/"><img src={smi} alt=""/></a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCafY2gShZXff-3whffG0Ihg"><img src={smy} alt=""/></a></li>
              </ul>
              <div className="terms">
              <div className="both">
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/fr/politique-de-confidentialite/">Politique de Confidentialité</a>
                <a target="_blank" rel="noopener noreferrer" href="https://shop.babueco.com/fr/conditions-generales/">Conditions générales</a>

              </div>
            </div>
         </div>
         <div className="copyright">
          <p>
              © {(new Date().getFullYear())} Babu | Un joyeux Noël 🎁
          </p>
        </div>

    </footer>
    )
  }
}

export default Footer
