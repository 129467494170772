import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter11 from './../../assets/chapter11.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Les énormes roseaux verts qui étaient éparpillés dans tout 
le royaume se mirent à fleurir et il en sortit d’autres CasseNoisettes qui aidèrent à piéger la Fée des Bonbons.


</p>
                    <p>Le Casse-Noisette de Clara retrouva son bras et tint une 
brosse à dents Babu. La Ballerine commença à danser. La 
mélodie de la chanson se répandit dans tout le royaume et 
récupéra tout ce qui avait été endommagé, y compris la Fée 
des bonbons qui se transforma en Fée des Dents.


</p>
                    <p>Toutes les souris ont pleuré de joie en voyant la Fée des 
dents, qui a couru les serrer dans ses bras et ensemble elles 
ont continué, en paix, à fabriquer leurs brosses à dents en 
bambou.


</p>
                    <p>- Ce royaume est magnifique. - dit Clara, émerveillée.</p>
<p>- Il est à toi maintenant, Reine Clara! - dit la ballerine en 
s’inclinant.</p><p>
- Mais je ne peux pas rester - dit Clara.

</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter10"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter12"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter11} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
