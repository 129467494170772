import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter4 from './../../assets/chapter4.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Miss Clara, you should have stayed in your room. This 
kingdom is very dangerous. - said the Nutcracker in an 
authoritarian voice.
</p>
                    <p>-They took my ballerina and I don’t understand why! Besides, 
your arm is broken and I can help you. - said little Clara.
</p>
                    <p>Nutcracker looked at his arm in a sad way and said:
</p>
                    <p>- We need to ask the Candy Fairy for help! I’ll tell you the 
story on the way
</p>
                    <p>As they walked through the forest full of green reeds the 
Nutcracker told Clara the story of the Green Kingdom. Many 
years ago the rat king turned the princess of the kingdom 
into a ballerina. The Candy Fairy tried to save her, but without 
success, because the mice used a dangerous weapon: the 
bamboo toothbrush!
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter3"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter5"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter4} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
