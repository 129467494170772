import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import fimimg from './../../assets/fim.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Les enfants se sont approchés de la boîte et ont trouvé des 
brosses à dents en bambou.</p>
                    <p>- A partir d’aujourd’hui, nous allons nous battre pour l’avenir 
de notre planète et notre plus grand pouvoir sera la créativité 
que nous saurons en faire. - dit Clara.
</p>
                    <p>- Et quand nous aurons fini d’utiliser les brosses en bambou, 
que ferons-nous avec elles? - demande une fille.
</p>
                    <p>- De la créativité! Je vais utiliser un des manches de ces 
brosses pour faire le bras de mon fidèle Casse-Noisette. - 
explique Clara, en souriant, en mettant le manche en bambou 
sur son Casse-Noisette.
</p>
                    <p>- Petite Mademoiselle Clara, où as-tu trouvé toutes ces 
brosses à dents? - demande la directrice de l’orphelinat.
</p>
                    <p>Clara regarde son casse-noisette, sourit et répond à la 
directrice :
</p>
                    <p>- C’est la magie de Noël!
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter13"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>

            </div>
        </article>

        <div className='ilustration'>
            <Link to="/story/chapter1"><img src={fimimg} alt=""/></Link>
        </div>
      </section>
    )
  }
}

export default Story
