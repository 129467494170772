import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter7 from './../../assets/chapter7.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Thank you Nutcracker for coming to me. I need your help 
to stop that terrible army! Everyone in the kingdom is afraid 
of them. And I think the Ballerina, in the wrong hands could 
destroy this wonderful place. Don’t you agree, little Clara? - 
asked the Candy Fairy.
</p>
                    <p>Clara and Nutcracker agreed to help the Candy Fairy and 
save the Ballerina. The Candy Fairy gave them an army of 
jelly beans all armed with plastic sticks in order to bring down 
the army of rats.
</p>
                    <p>Nutcracker and Clara walked towards the rats together with 
the army of the Candy Fairy until they heard a noise among 
the huge green reeds. It was more of the Candy Fairy’s army, 
but something was wrong as they began to surround them 
with a fierce and threatening look.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter6"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter8"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter7} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
