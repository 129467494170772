import React, {Component} from "react";
import { BrowserRouter as Router, Route, Link} from "react-router-dom";
import Cookies from 'js-cookie'


/*Images*/ 
import conta from './../../assets/icons/icon-perfil.png'
import cart from './../../assets/icons/icon-carrinho.png'



/* Test Imports */ 


import logo from "./../../assets/logos/natal-logo.png"


const Selector = ()=>{
  let language = localStorage.getItem("chosenlanguage");

  const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const langChange = (e)=>{
    let lang = e.target.value;
    console.log(e.target.value);
    localStorage.setItem("chosenlanguage", lang)
    switch(lang){
      case 'pt-PT':
          document.getElementById("pt").click();break;
      case 'es-ES':
          document.getElementById("es").click();break;
      case 'fr-FR':
          document.getElementById("fr").click();break;
      default:
          document.getElementById("en").click();break;
  }
  }
 return(
  <div className="selector-wrapper">
    <select name="lang" id="langSelect" value={language} onChange={(e)=>langChange(e)}>
      <option value="en-GB">EN</option>
      <option value="es-ES">ES</option>
      <option value="fr-FR">FR</option>
      <option value="pt-PT">PT</option>
    </select>
  </div>
 )
}

class Header extends Component {
    constructor(props,context){
        super(props,context)
        this.state={
          openClose:false,
          lang:"",
        }
        this.openCloseMenu = this.openCloseMenu.bind(this);
      }

      langChange(e){
        let lang = e.target.value;
        this.setState({lang});
        localStorage.setItem("chosenlanguage", lang)
      }
    
      openCloseMenu(event){
        
        this.setState({openClose: !this.state.openClose})
        let body = document.getElementsByTagName('body')[0]
        body.classList.toggle('menu-open');
        if(body.classList.contains('menu-open') === this.state.openClose){
          body.classList.toggle('menu-open');
        }
      }
      
      catchingIndex(theindex){
        this.setState({indexservices:theindex})
      }
      
  render() {
    let url='https://blog.babueco.com';
    let item_count = Cookies.get('shopCartCount');

    return (

              <header className="menu">
              <nav className="navegacao">
                  <div className="navegacao__logo">
                      <Link to="/"><img src={logo} alt=""/></Link>
                  </div>

                  <div className="navegacao__menus">
                      <Selector />
                    <div className="menus__perfil icon">
                        <a href="https://shop.babueco.com/en/my-account/">
                        <img src={conta} alt=""/>
                        </a>
                    </div>
                    <div className="menus__carrinho icon">
                       <a href="https://shop.babueco.com/en/basket/">
                        <img src={cart} alt=""/>
                        {item_count ? <span className="bolinha">{item_count}</span> : ""}
                        </a>
                    </div>
                      <div className="menus__hamburger" >
                          <div className="hamburger"   onClick={this.openCloseMenu} ><span></span></div>
                          <div className={ this.state.openClose ? "itens__hamburger opened" : "itens__hamburger"} onClick={this.openCloseMenu}>
                            <ul>
                                <a href="#produtos"><li className="menu-item">Buy now</li></a>
                                <a href="#quebraNozes"><li className="menu-item">Nutcracker</li></a>
                                <a href="#embalagens"><li className="menu-item">Fun packaging</li></a>
                                <a href="#certificados"><li className="menu-item">Certifications</li></a>
                                <a href="https://babueco.com"><li className="menu-item">Babu</li></a>
                            </ul>
                            <div className="filler"></div>
                          </div>
                      </div>
                  </div>
              </nav>
      </header> 

    )
  }
}


export default Header
