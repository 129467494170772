import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter10 from './../../assets/chapter10.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>“Quand les souris se rendirent compte que la reine, la 
mère de Clara, était très malade et que les casse-noisettes 
étaient plus faibles, elles proposèrent un plan, s’enfuir avec 
la princesse Clara, prendre la ballerine et le casse-noisette 
pour la protéger, jusqu’à ce qu’elle soit assez grande pour 
sauver le royaume.

</p>
                    <p>- Mais, comment puis-je sauver le Royaume de Babu? - 
demande Clara.

</p>
                    <p>- Tout ce dont tu as besoin est en toi. Tu as un pouvoir unique! 
- répond la ballerine d’une voix douce.

</p>
                    <p>Clara a eu une idée ! Elle a demandé aux souris de l’aider 
à construire une énorme boîte jaune. Pendant ce temps, 
Casse-Noisette a attiré l’armée de gommes, avec des bâtons 
en plastique, dans la boîte jaune. Dès que l’armée passe 
dans la boîte jaune, les gommes se transforment en nuages 
et le plastique prend lui aussi une nouvelle vie.

</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter9"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter11"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter10} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
