import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter13 from './../../assets/chapter13.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Acorda Clara! Acorda! Queremos pedir desculpa por te 
termos estragado o Quebra-nozes, mas temos uma solução! 
Olha para este braço musculado! – disse um dos meninos 
do orfanato enquanto Clara despertava do seu sonho.

</p>
                    <p>- É feito de plástico? – bocejou Clara, enquanto se 
espreguiçava.

</p>
                    <p>-Anh...?! – disse o menino.

</p>
                    <p>Clara sorriu e levou todas as crianças para o jardim do 
orfanato enquanto caminhava para trás de um arbusto.

</p>
                    <p>- Descobri que todos podemos ser um herói. Tudo o que 
precisamos está dentro de nós e desta linda caixa. – gritou 
alegremente Clara.


</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter12"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/fim"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter13} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
