import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter11 from './../../assets/chapter11.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Los enormes juncos verdes que estaban dispersos por 
todo el reino, empezaron a florecer y de ellos surgieron 
más Cascanueces que ayudaron a atrapar al Hada de los 
Caramelos.


</p>
                    <p>El Cascanueces de Clara recuperó su brazo y sujetó un 
cepillo de dientes de Babú. La Bailarina comenzó a bailar. 
La melodía de la canción se difundió por todo el reino y 
recuperó todo lo que estaba dañado, incluido el Hada de 
los Caramelos que se convirtió en el Hada de los Dientes.


</p>
                    <p>Todos los ratones lloraron de alegría al ver al Hada de los 
Dientes, que corrió a abrazarlos y juntos siguieron, en paz, 
haciendo sus cepillos de dientes de bambú.


</p>
                    <p>- Este reino es precioso. - dijo Clara maravillada.</p>
<p>- ¡Ahora es tuyo, reina Clara! - dijo la bailarina mientras se 
inclinaba.</p><p>
- Pero no puedo quedarme - dijo Clara.

</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter10"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter12"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter11} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
