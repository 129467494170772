import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter4 from './../../assets/chapter4.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Menina Clara, devia ter ficado no seu quarto. Este reino é muito perigoso. – disse o Quebra-nozes com uma voz autoritária.
</p>
                    <p>-Eles levaram a minha bailarina e eu não percebo porquê! Além disso o teu braço está partido e eu posso ajudar-te. – disse a pequena Clara.
</p>
                    <p>Quebra-nozes olhou para o seu braço de uma forma triste e disse:
</p>
                    <p>- Precisamos de pedir ajuda à Fada dos Doces! Eu conto-te a história pelo caminho. 
</p>
                    <p>Ao abrir os olhos, Clara ficou assustada com o exército de 
ratos que se encontrava na direção da sua bailarina, todos 
eles transportavam uma escova de dentes em bambu. De 
repente surge um soldado alto e valente que tentou impedir 
que os ratos levassem a bailarina, mas sem sucesso.
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter3"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter5"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter4} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
