import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

//import ilustration from './../../assets/'

import chapter1 from './../../assets/chapter1.png'
import letterE from './../../assets/e.png'



class Story extends Component {
    constructor(){
        super()
        this.state={
            
        }

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_title'>
                    <h1>Quebra-nozes</h1>
                    <h2>O Reino Babu</h2>
                </div>
                <div className='story_pg'>
                    <div className='firstparagraph'>
                        <img src={letterE} alt=""/>
                        <p className='firstp'>ra véspera de Natal e enquanto a neve cobria a cidade 
com as suas vestes brancas uma doce menina aguarda 
pelo seu adorado padrinho. Clara era uma menina órfã 
que vivia com mais crianças num antigo orfanato e todos os 
anos recebiam a visita dos seus padrinhos com presentes 
de natal</p>
                    </div>
                    <p>Quando a campainha tocou, todas as crianças correram para 
a porta na esperança que fosse o padrinho delas, mas era o 
padrinho de Clara quem tinha chegado, com uma pequena 
caixa embrulhada em papel vermelho e um laço verde. 
Clara sorriu e rapidamente desembrulhou o presente. Era 
um lindo Quebra-Nozes!</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter2"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter1} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
