import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter6 from './../../assets/chapter6.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- What is your name? - said a beautiful woman with huge 
wings as she landed in front of Clara.
</p>
                    <p>- Me? I’m Clara! - she said confused.
</p>
                    <p>Nutcracker quickly bowed to the Candy Fairy and said he 
needed to talk to her as he had information of interest to her. 
The Candy Fairy ignored Nutcracker and continued talking 
to Clara, while looking at her mysteriously.
</p>
                    <p>- Clara, dear, is that your only name? What are you doing in 
the Green Kingdom?
</p>
                    <p>- No, I’m Clara Babu and I’ve come to pick up my ballerina 
with the... - Clara is quickly interrupted by the Candy Fairy 
who invites them into her palace.
</p>
                    <p>As they entered Nutcracker was silent and thoughtful looking 
at Clara, for her name was not strange to him. The Candy 
Fairy invited them to sit down and served them a huge snack 
while looking at Nutcracker
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter5"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter7"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter6} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
