import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter10 from './../../assets/chapter10.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Todos os Quebra-nozes e também a mãe de Clara foram 
eliminados pela Fada dos Doces que procurou a princesa 
todos estes anos. A Fada dos Doces também nos conseguiu 
enfeitiçar alterando parte da nossa memória.”

</p>
                    <p>- Mas, como é que eu posso salvar o Reino Babu? – perguntou 
Clara.

</p>
                    <p>- Tudo o que precisas está dentro de ti. Tens um poder único! 
- respondeu a Bailarina com uma voz doce.

</p>
                    <p>Clara teve uma ideia! Pediu a ajuda dos ratos para construírem 
uma enorme caixa amarela. Enquanto isso o Quebra-nozes 
atraia o exército de Gomas, com pauzinhos de plástico, para 
dentro da caixa amarela. Assim que o exército passou pela 
caixa amarela as gomas transformaram-se em nuvens e o 
plástico também ganhou uma nova vida.

</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter9"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter11"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter10} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
