import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter4 from './../../assets/chapter4.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Mlle Clara, vous auriez dû rester dans votre chambre. Ce 
royaume est très dangereux. - dit le Casse-Noisette d’une 
voix autoritaire.
</p>
                    <p>- Ils ont pris ma ballerine et je ne comprends pas pourquoi 
! En plus, ton bras est cassé et je peux t’aider. - dit la petite 
Clara.
</p>
                    <p>Casse-Noisette regarde son bras d’une manière triste et dit :
</p>
                    <p>- Il faut demander de l’aide à la fée des bonbons ! Je te 
raconterai l’histoire en chemin.</p>
                    <p>Alors qu’ils traversaient la forêt pleine de roseaux verts, 
Casse-Noisette raconta à Clara l’histoire du Royaume Vert. 
Il y a de nombreuses années, le roi des rats a transformé 
la princesse du royaume en ballerine. La fée des bonbons 
a essayé de la sauver, mais sans succès, car les souris ont 
utilisé une arme dangereuse : la brosse à dents en bambou !
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter3"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter5"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter4} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
