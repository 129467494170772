import React, { Component } from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Draggable from 'react-draggable'
import {isMobile, BrowserView,MobileView} from 'react-device-detect';

import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import Chapter1 from './../chapters/Chapter1'
import Chapter2 from './../chapters/Chapter2'
import Chapter3 from './../chapters/Chapter3'
import Chapter4 from './../chapters/Chapter4'
import Chapter5 from './../chapters/Chapter5'
import Chapter6 from './../chapters/Chapter6'
import Chapter7 from './../chapters/Chapter7'
import Chapter8 from './../chapters/Chapter8'
import Chapter9 from './../chapters/Chapter9'
import Chapter10 from './../chapters/Chapter10'
import Chapter11 from './../chapters/Chapter11'
import Chapter12 from './../chapters/Chapter12'
import Chapter13 from './../chapters/Chapter13'
import Fim from './../chapters/Fim'


import MP3 from './../../assets/audio/book_en.mp3'



class Story extends Component {
    constructor(){
        super()
        this.state={
            open: isMobile ? false : true
        }
        this.handleWindow=this.handleWindow.bind(this)
    }

handleWindow(){
    this.setState({open:!this.state.open})
}
  render() {
    return (
      <main className="story_main">  
      <BrowserView>
        <Draggable>
            <div className='jackbox'> 
                <div className='close-button'><span className='Hide' onClick={this.handleWindow}>{this.state.open? 'x' : 'Open player'}</span></div>
                <div  className='jackbox_container' style={this.state.open ? {opacity:1} : {opacity:0}}>
                    <div className='audio-title'>
                            <h4>You can listen to the story told by us </h4>   
                        </div>
                    <AudioPlayer style={{width: '300px', margin:'0 auto'}}src={MP3} />
                </div>
            </div>
         </Draggable>
        </BrowserView>
        <MobileView>
            <div className='jackbox'> 
                <div className='close-button'><span className='Hide' onClick={this.handleWindow}>{this.state.open? 'x' : 'Open player'}</span></div>
                <div  className='jackbox_container' style={this.state.open ? {opacity:1} : {opacity:0}}>
                    <div className='audio-title'>
                            <h4>You can listen to the story told by us </h4>   
                        </div>
                    <AudioPlayer style={{width: '300px', margin:'0 auto'}}src={MP3} />
                </div>
            </div>
        </MobileView>

        <Routes>
            <Route path="chapter1" element={<Chapter1/>}/>
            <Route path="chapter2" element={<Chapter2/>}/>
            <Route path="chapter3" element={<Chapter3/>}/>  
            <Route path="chapter4" element={<Chapter4/>}/>  
            <Route path="chapter5" element={<Chapter5/>}/>  
            <Route path="chapter6" element={<Chapter6/>}/>  
            <Route path="chapter7" element={<Chapter7/>}/>  
            <Route path="chapter8" element={<Chapter8/>}/>  
            <Route path="chapter9" element={<Chapter9/>}/>  
            <Route path="chapter10" element={<Chapter10/>}/>  
            <Route path="chapter11" element={<Chapter11/>}/>  
            <Route path="chapter12" element={<Chapter12/>}/>  
            <Route path="chapter13" element={<Chapter13/>}/>  
            <Route path="fim" element={<Fim/>}/>
        </Routes>

      </main>
    )
  }
}

export default Story
