import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter8 from './../../assets/chapter8.png'





class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Uma forte luz cega por breves segundos o Quebra-nozes até que ele grita:
</p>
                    <p>- Clara tu és a princesa! Este é o Reino Babu e eu sou o último Quebra-nozes!
</p>
                    <p>Clara olha para ele confusa até que aparece a sua Bailarina 
e os ratos para os salvarem.
</p>
                    <p>- Rápido minha querida, temos de te proteger da Fada dos 
Doces. - disse a Bailarina enquanto segurava a mão de 
Clara

</p>
                    <p>-  Quebra-nozes! – gritou Clara, enquanto se afastava do seu 
fiel amigo, que lutava com o enorme exército de gomas ao 
lado dos ratos

</p>
                    <p>Num local, seguro e acolhedor a Bailarina explicou a Clara a 
sua verdadeira história. O nome verdadeiro do Reino Verde 
era o Reino Babu e tinha sido criado pelos seus antepassados 
com o objetivo de cuidar da felicidade das pessoas e do 
planeta Terra através da planta com o crescimento mais 
rápido do mundo, o bambu.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter7"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter9"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter8} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
