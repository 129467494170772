import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import fimimg from './../../assets/fim.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>As crianças aproximaram-se da caixa e encontraram escovas 
de dentes em bambu.
</p>
                    <p>- A partir de hoje, lutaremos pelo futuro do nosso planeta e 
o nosso maior poder será a criatividade que podemos fazer 
com o bambu. – disse Clara.
</p>
                    <p>-  E quando acabarmos de utilizar as escovas de bambu o 
que fazemos com elas? – perguntou uma menina.
</p>
                    <p>- Criatividade! Eu vou utilizar um dos cabos, destas escovas, 
para ser o braço do meu fiel Quebra-nozes. – explicou 
Clara, a sorrir, enquanto colocava o cabo de bambu no seu 
Quebra-nozes.
</p>
                    <p>- Menina Clarinha, onde arranjou estas escovas todas? – 
perguntou a Diretora do Orfanato.
</p>
                    <p>Clara olhou para o seu Quebra-nozes, sorriu e respondeu à 
Diretora:
</p>
                    <p>- É a magia do Natal!
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter13"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>

            </div>
        </article>

        <div className='ilustration'>
            <Link to="/story/chapter1"><img src={fimimg} alt=""/></Link>
        </div>
      </section>
    )
  }
}

export default Story
