import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter5 from './../../assets/chapter5.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Ao chegarem ao palácio da Fada dos Doces foram mandados 
parar pelos soldados goma.

</p>
                    <p>- Ora! Ora! Quem temos aqui! O último Quebra-nozes com 
uma menina... – disse um dos soldados.
</p>
                    <p>- O último Quebra-nozes?! – pensaram Clara e Quebranozes.
</p>
                    <p>- O que querem daqui? – interrompeu o segundo soldado.
</p>
                    <p>Ao ver o olhar triste e confuso de Quebra-nozes, Clara 
decidiu assumir a conversa.
</p>
                    <p>- Viemos pedir ajuda à Fada dos Doces! Os ratos roubaram 
a minha bailarina e o Quebra-nozes pensa que poderá ser a 
princesa deste reino. – afirmou Clara.
</p>
                    <p>Os dois soldados olharam para eles e começaram a rir às 
gargalhadas até que chegou uma sombra que os deixou 
sem a luz do sol. Ao verem a sombra, os soldados, pararam 
de rir e ficaram imediatamente em sentido.
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter4"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter6"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter5} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
