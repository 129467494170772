import React from 'react';
import ScrollToTop from './components/ScrollToTop'
import Home from './pages/home'
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import Snowfall from 'react-snowfall'

// componennts 
import Header from './components/header'
import Footer from './components/footer'


import Story from './pages/Story'
import Chapter1 from './chapters/Chapter1'
import Chapter2 from './chapters/Chapter2'
import Chapter3 from './chapters/Chapter3'
import Chapter4 from './chapters/Chapter4'
import Chapter5 from './chapters/Chapter5'
import Chapter6 from './chapters/Chapter6'
import Chapter7 from './chapters/Chapter7'
import Chapter8 from './chapters/Chapter8'
import Chapter9 from './chapters/Chapter9'
import Chapter10 from './chapters/Chapter10'
import Chapter11 from './chapters/Chapter11'
import Chapter12 from './chapters/Chapter12'
import Chapter13 from './chapters/Chapter13'
import Fim from './chapters/Fim'


function App() {
  return (
    <div className="wrapper">
      <Snowfall/>
      <BrowserRouter>
        <Header/>
              <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/story/*" element={<Story/>}>
                  <Route path="chapter1" element={<Chapter1/>}/>
                  <Route path="chapter2" element={<Chapter2/>}/>
                  <Route path="chapter3" element={<Chapter3/>}/>  
                  <Route path="chapter4" element={<Chapter4/>}/>  
                  <Route path="chapter5" element={<Chapter5/>}/>  
                  <Route path="chapter6" element={<Chapter6/>}/>  
                  <Route path="chapter7" element={<Chapter7/>}/>  
                  <Route path="chapter8" element={<Chapter8/>}/>  
                  <Route path="chapter9" element={<Chapter9/>}/>  
                  <Route path="chapter10" element={<Chapter10/>}/>  
                  <Route path="chapter11" element={<Chapter11/>}/>  
                  <Route path="chapter12" element={<Chapter12/>}/>  
                  <Route path="chapter13" element={<Chapter13/>}/>  
                  <Route path="fim" element={<Fim/>}/>
                </Route>
              </Routes>        
        <Footer/>
      </BrowserRouter>
    </div>
    );
}

export default App;
