import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter5 from './../../assets/chapter5.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>When they reached the Candy Fairy’s palace they were 
stopped by the jelly beans soldiers.

</p>
                    <p>- Well, well, well! Who have we here? The last Nutcracker 
with a little girl... - said one of the soldiers.
</p>
                    <p> “The last Nutcracker?” thought Clara and Nutcracker.

</p>
                    <p>- What do you want from here? - interrupted the second 
soldier.
</p>
                    <p>Seeing Nutcracker’s sad and confused look, Clara decided 
to take over the conversation.
</p>
                    <p>- We have come to ask the Candy Fairy for help! The rats 
have stolen my ballerina and the Nutcracker thinks she 
might be the Princess of this kingdom. - said Clara.
</p>
                    <p>The two soldiers looked at them and started laughing out 
loud until a shadow came and left them without the sunlight. 
Upon seeing the shadow, the soldiers stopped laughing and 
immediately stood to attention.
</p>


                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter4"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter6"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter5} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
