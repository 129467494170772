import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter7 from './../../assets/chapter7.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Gracias Cascanueces por venir a mi encuentro. ¡Necesito 
tu ayuda para detener a ese terrible ejército! Todos en el 
reino les tienen miedo. Y creo que la Bailarina, en las manos 
equivocadas podría destruir este precioso lugar. ¿No estás 
de acuerdo, pequeña Clara? - preguntó el Hada de los 
Caramelos.
</p>
                    <p>Clara y Cascanueces aceptaron ayudar al Hada de los 
Caramelos y salvar a la Bailarina. El Hada de los Caramelos 
les dio un ejército de gomas armados con bastones de 
plástico para acabar con el ejército de ratones.
</p>
                    <p>Cascanueces y Clara caminaron hasta los ratones junto con 
el ejército del Hada de los Caramelos hasta que escucharon 
un ruido entre los enormes juncos verdes. Eran más del 
ejército del Hada de los Caramelos, pero algo iba mal ya que 
empezaron a rodearlas con una mirada feroz y amenazadora.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter6"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter8"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter7} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
