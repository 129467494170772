import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

import chapter12 from './../../assets/chapter12.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Todos ficaram com um olhar confuso e triste, até que aparece 
o Quebra-nozes.
</p>
                    <p>- Nós sabemos! O teu poder pode chegar a milhões de 
pessoas. Basta que o partilhes. – sorriu o Quebra-nozes.
</p>
                    <p>- No planeta Terra todos podemos ser um herói, só tenho de 
mostrar às pessoas como o podem fazer. –disse Clara, com 
esperança na voz.
</p>
                    <p>Clara pediu que fosse a Bailarina a governar o reino na 
ausência dela, e o Quebra-nozes ofereceu-se para a ajudar 
na sua missão pelo planeta Terra.
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter11"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter13"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter12} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
