import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter7 from './../../assets/chapter7.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Obrigada Quebra-nozes por vires até a mim. Preciso da 
vossa ajuda para parar com aquele exército terrível! Todas as 
pessoas do reino têm medo deles. E penso que a Bailarina, 
nas mãos erradas pode destruir este lugar maravilhoso. Não 
concordas, Clarinha? – perguntou a Fada dos Doces.
</p>
                    <p>Clara e Quebra-nozes concordaram em ajudar a Fada dos 
Doces e salvar a Bailarina. A Fada dos Doces deu-lhes um 
exército de gomas todos eles armados com pauzinhos de 
plástico de forma a derrubar o exército de ratos.
</p>
                    <p>Quebra-nozes e Clara caminharam ao encontro dos ratos 
juntamente com o exército da Fada dos Doces até que 
ouvem um barulho entre as enormes canas verdes. Era 
mais exército da Fada dos Doces, mas alguma coisa se 
passava, pois, começaram a cercá-los com um olhar feroz e 
ameaçador.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter6"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter8"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter7} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
