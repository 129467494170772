import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter3 from './../../assets/chapter3.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Un énorme bruit a réveillé Clara !
</p>
                    <p>Ouvrant les yeux, Clara est effrayée par l’armée de rats qui 
se dirige vers sa ballerine. Ils portaient tous une brosse à 
dents en bambou.<br/>
Soudain, un grand et courageux soldat est apparu et a 
essayé d’empêcher les rats de prendre la ballerine, mais 
sans succès.</p>
                    <p>- Casse-Noisette ? - demande Clara, surprise - “Rendez-moi 
ma ballerine !” - a crié en direction des rats.</p>
                    <p>- Je vais vous sauver ! - cria Casse-Noisette en courant vers 
le trou d’où les rats s’étaient échappés.</p>
                    <p>Clara saute du lit et court après eux, et c’est à ce moment-là 
qu’elle se rend compte qu’elle a rétréci.</p>
                    <p>En passant par le trou, Clara est arrivée dans un endroit 
magnifique rempli de roseaux verts.</p>

                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter2"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter4"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter3} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
