import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter3 from './../../assets/chapter3.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Un enorme ruido despertó a Clara.</p>
                    <p>Al abrir los ojos, Clara se sobresaltó al ver el ejército de 
ratones que caminaba en dirección a su bailarina. Todos 
llevaban un cepillo de dientes de bambú.
Repentinamente apareció un soldado alto y bravo que 
intentó impedir que los ratones llevasen a la bailarina, pero 
sin éxito.</p>
                    <p>- ¿Cascanueces? - preguntó Clara, sorprendida - 
“¡Devuélvanme mi bailarina!” - gritó en dirección a los 
ratones.</p>
                    <p>- ¡Te salvaré! - gritó el Cascanueces mientras corría en 
dirección al agujero por el que se habían escapado los 
ratones.</p>
                    <p>Clara saltó de la cama y corrió tras ellos, y fue en ese 
momento que se dio cuenta de que había encogido.</p>
                    <p>Al pasar por el agujero, Clara llegó a un hermoso lugar lleno 
de juncos verdes.</p>

                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter2"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter4"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter3} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
