import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter13 from './../../assets/chapter13.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Wake up, Clara! Wake up! We want to apologise for messing 
up your Nutcracker, but we have a solution! Look at that 
muscular arm! - said one of the children of the orphanage as 
Clara awoke from her dream.

</p>
                    <p>- Is it made of plastic? - yawned Clara, as she stretched.

</p>
                    <p>- Anh?... - said the boy.

</p>
                    <p>Clara smiled and led all the children into the orphanage 
garden as she walked behind a bush.

</p>
                    <p>- I have discovered that we can all be a hero. Everything we 
need is inside us and this beautiful box. - shouted Clara happily.


</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter12"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/fim"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter13} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
