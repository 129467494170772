import React, { Component } from 'react'
import $ from "jquery";
import {Link} from 'react-router-dom'


//animation
import Fade from 'react-reveal';


import 'react-multi-carousel/lib/styles.css';




/* images */
import fotoproduct from './../../assets/produtos/image1.png'
import fantoches from './../../assets/produtos/image2.png'
import certs from './../../assets/produtos/certp.jpg'
import estadio from './../../assets/bg1.png'
import pggh from './../../assets/pggh.jpg'
import leftBrush from './../../assets/produtos/leftbrush.png'
import rightBrush from './../../assets/produtos/rightbrush.png'
import featureImg from './../../assets/produtos/img1fr.png'
import certifi from './../../assets/certificados.jpg'

/*imagens produtos */

import produto1 from './../../assets/produtos/pd-qb.png'
import produto2 from './../../assets/produtos/pd-ps.png'
import produto3 from './../../assets/produtos/pd-bl.png'
import produto4 from './../../assets/produtos/pd-rt.png'



class Home extends Component {
    constructor(){
        super()
        this.state={
            produtos:[
                {name:"Casse-Noisette", img:produto1, link:"https://shop.babueco.com/fr/produto/casse-noisette-et-princesse/?attribute_pa_escolhe-o-teu-aliado=quebra-nozes-en-es-fr"},
                {name:"Princesse", img:produto2, link:"https://shop.babueco.com/fr/produto/casse-noisette-et-princesse/?attribute_pa_escolhe-o-teu-aliado=princesa-en-es-fr"},
                {name:"Ballerine", img:produto3, link:"https://shop.babueco.com/fr/produto/ballerine-et-petite-souris/?attribute_pa_aliado-kids=bailarina-en-es-fr"},
                {name:"Petite souris", img:produto4, link:"https://shop.babueco.com/fr/produto/ballerine-et-petite-souris/?attribute_pa_aliado-kids=ratinho-en-es-fr"},
            ],
        }
        this.handleForm = this.handleForm.bind(this)
        this.openForm = this.openForm.bind(this)
        this.handleAddition = this.handleAddition.bind(this)
    }

    componentDidMount() {
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();

                document.querySelector(this.getAttribute('href')).scrollIntoView({
                    behavior: 'smooth'
                });
            });
        });
    }

    openForm(){
        this.setState({
            formactivation:!this.state.formactivation
        })
    }
    handleForm(e){
        e.preventDefault();
        this.setState({
        contactEmail: '',
        contactMessage: '',
        formactivation:!this.state.formactivation
        });
    
        $.ajax({
        url: process.env.NODE_ENV !== "production" ? '/getMail' : "newsletter.php",
        type: 'POST',
        data: {
            'email': this.state.email,
        },
        cache: false,
        success: function(data) {
            // Success..
            this.setState({
            contactEmail: 'success',
            contactMessage: '<div class="sucess_contact"><h2>Foi adicionado à nossa lista!</h2></div>'
            });
            $('#containerText').slideUp();
            $('#containerText').after(this.state.contactMessage);
            console.log('success', data);
        }.bind(this),
        // Fail..
        error: function(xhr, status, err) {
            console.log(xhr, status);
            console.log(err);
            this.setState({
            contactEmail: 'danger',
            contactMessage: '<h1>Pedimos desculpa mas houve um erro no algoritmo</h1><p>Tente outra vez mais tarde, ou contacte directamente pelo info@babueco.com</p>'
            });
            console.log(this.state.email + this.state.message + 'fail');
        }.bind(this)
        })
    }

    handleAddition(e){
        this.setState({
          [e.target.name]: e.target.value,
        });
      }

  render() {
//  style for first sec // style={{background: `url(${bgImg}) no-repeat center center`, backgroundSize:`cover`}}
    return (
        <main className="home_babu">
            
            <header id="home" className="hero masthead" style={{background: `url(${estadio}) no-repeat center center`,backgroundSize:`cover`}}>
                <div className="cta__ hero__container">
                    <Fade forever left >
                        <div className="cta__image">
                            <img src={featureImg} alt="" />
                        </div>
                    </Fade>
                    <Fade forever right>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Ce Noël, tout ce dont vous avez besoin est en vous !</h2>
                                <div className="buttons-row">
                                    <a href="#produtos"><button className="button__babu" style={{fontSize:'14px'}}>Achetez maintenant</button></a>
                                    <Link to="/story/chapter1"><button className="button__babu">L'histoire</button></Link>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
               
            </header>
            <section id="quebraNozes" className="hero firstsec">
                <Fade forever left>
                    <div className="left-brush">
                        <img src={leftBrush} alt=""/>
                    </div>
                </Fade>
                <div className="principal">
                    <Fade forever >
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <div className="natal-title">
                                    <h2>Casse-Noisette</h2>
                                    <h4 style={{color:"#bed600"}}>Le royaume de Babu</h4>
                                </div>
                                <p>
                                    Découvrez l'histoire de Casse-Noisette et de la princesse Clara. Et partagez-la avec vos proches à Noël. <br/>
                                    <span style={{color:'#999',fontSize:'12px'}}>Cette collection est limitée au stock existant </span>
                                </p>
                                
                                <Link to="/story/chapter1"><button className="button__babu">Lisez l'histoire</button></Link>
                            </div>
                        </div>
                    </Fade>
                </div>
                 <Fade forever right>
                    <div className="right-brush">
                        <img src={rightBrush} alt=""/>
                    </div>
                </Fade>
            </section>
            <section id="produtos" className="itens-section">
                <Fade forever delay={100}>
                    <div className="natal-title title">
                        <h2>LE VRAI HÉROS, C'EST TOI</h2>
                        <h4>CHOISISSEZ VOTRE ALLIÉ</h4>
                    </div>
                </Fade>
                <div className="item-list">
                    <Fade forever delay={300} cascade>
                        {this.state.produtos.map((item, index)=>{
                                return(
                                    <div  key={index + "item"} className="items_container">
                                        <div className="items_content">
                                            <div className="item">
                                                <a target="_blank" href={item.link} >
                                                    <img src={item.img} alt=""/>
                                                    <div className="item_link">{item.name}</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                    </Fade>
                </div>
            </section>
            <section id="porque" className="hero buysec">
                <div className="cta__">
                    <Fade forever bottom delay={50}>
                        <div className="cta__image">
                            <img src={fotoproduct} alt="" />
                        </div>
                    </Fade>
                    <Fade forever bottom delay={200}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Un pack pour toute la famille</h2>
                                <p>Certaines histoires méritent d'être partagées avec toute la famille. C'est pourquoi nous avons créé un pack plein d'amour.</p>
                                <div className="buttons-row">
                                    <a href="https://shop.babueco.com/fr/produto/pack-famille-edition-casse-noisette"><button className="button__babu" style={{fontSize:'14px'}}>Achetez maintenant</button></a>
                                    <Link to="/story/chapter1"><button className="button__babu">L'histoire</button></Link>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
            </section>
            <section id="embalagens" className="hero buysec">
                <div className="cta__">
                    <Fade forever bottom delay={200}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Utilisez les emballages comme marionnettes</h2>
                                <p>Créativité ! Vous pouvez réutiliser les emballages et les transformer en petites marionnettes et recréer l'histoire de Casse-Noisette ou même créer votre propre histoire. </p>
                            </div>
                            <img style={{maxWidth:'300px'}} src={certifi} alt=""/>
                        </div>
                    </Fade>
                    <Fade forever bottom delay={50}>
                        <div className="cta__image fantoche">
                            <img src={fantoches} alt="" />
                        </div>
                    </Fade>
                </div>
            </section>
           {/*  <section id="certificados" className="hero certs">
                <div className="cta__">
                    <Fade forever bottom delay={100}>
                        <div className="cta__image">
                            <img src={certs} alt="" />
                        </div>
                    </Fade>
                    <Fade forever bottom delay={50}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Certificações que valem golos</h2>
                                <p style={{marginBottom:"10px"}}>
                                    Todos os produtos da Babu são certificados <em style={{fontStyle:'italic', fontWeight:"bold"}}>Vegan</em> pela <em style={{fontStyle:'italic', fontWeight:"bold"}}>Vegan Society</em> e <em style={{fontStyle:'italic', fontWeight:"bold"}}>Cruelty-free</em> pela PETA.org.
                                </p>
                                <p>
                                    Os parceiros de produção são certificados pela <strong>amfori BSCI</strong> por cumprirem critérios de satisfação do trabalho,
                                    bem como por várias outras entidades de certificação de qualidade e sustentabilidade,
                                    tais como:
                                    <ul className="list-cert">
                                        <li><strong>Certificado de Conformidade de RoHS</strong></li>
                                        <li><strong>Certificação FSC de Cadeia de Custódia</strong></li>
                                        <li><strong>Certificação de Sistemas de Gestão da Qualidade ISO 9001</strong></li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </Fade>

                </div>
            </section>*/}

            <section id="babu" className="hero redbackground">
                <div className="blockup"></div>
                <div className="cta__">
                <Fade forever delay={300}><div className="cta__image"> <img className="diagonal--inverted" src={pggh} alt=""/></div></Fade>
                    <Fade forever delay={1000}>
                        <div className="cta__containertext">
                            <div className="container__text--white">
                                <h2>Petits gestes, grands héros !</h2>
                                <p>
                                    Babu est une marque née de la conviction que nous pouvons et devons créer des solutions écologiques pour les besoins actuels de chacun d'entre nous, contribuant ainsi au changement que nous souhaitons tous.
                                </p>
                                <p>
                                La mission de Babu va bien au-delà de la production de ses produits phares. En choisissant Babu, vous ne faites pas seulement un choix écologique, vous contribuez également à toutes les causes que la marque soutient, comme la préservation de l'environnement, la conservation des espèces et la sensibilisation des jeunes générations à de meilleures habitudes.
                                </p>
                                <div className="buttons-row">
                                    <a href="https://babueco.com/produtos"><button className="button__babu">Produits Babu</button></a>
                                    <a href="https://babueco.com/sobre"><button className="button__babu">La Babu</button></a>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </div>
                <div className="blockdown"></div>

            </section>
            <section className="hero subscription">
                <Fade forever left>
                    <div className="cta__--white">
                        <div className="cta__containertext">
                            <div className="container__text" id="containerText">
                                <h2>
                                    Connaissez-vous notre newsletter ?
                                </h2>
                                <form className="news__sub" action="newsletter.php" onSubmit={this.handleForm}>
                                    <input type="email" name="email" onChange={this.handleAddition} placeholder="Entrez votre courriel" required/>
                                    <div className="privacy_sub"><input type="checkbox" name="privacy" onChange={this.handleAddition} required/><p><span>J'ai lu et compris la  <a href="http://shop.babueco.com/en/privacy-policy/">Politique de Confidentialité </a> de Babu.</span></p></div>
                                    <button onSubmit={()=>this.handleForm()} className="button__babu">Envoyer</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </Fade>
            </section>
      </main>
    )
  }
}

export default Home

