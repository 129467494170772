import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter13 from './../../assets/chapter13.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- ¡Despierta, Clara! ¡Despierta! Queremos pedir disculpas por 
destrozar tu Cascanueces, ¡pero tenemos una solución! ¡Mira 
ese brazo musculoso! - dijo uno de los niños del orfanato 
cuando Clara despertó de su sueño.

</p>
                    <p>- ¿Es de plástico? - bostezó Clara, mientras se estiraba.

</p>
                    <p>- ¿Anh...? - dijo el niño.

</p>
                    <p>Clara sonrió y guió a todos los niños al jardín del orfanato 
mientras caminaba detrás de un arbusto.

</p>
                    <p>- He descubierto que todos podemos ser un héroe. Todo lo 
que necesitamos está dentro de nosotros y de esta preciosa 
caja. - gritó felizmente Clara.


</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter12"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/fim"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter13} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
