import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter13 from './../../assets/chapter13.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>- Réveille-toi, Clara! Réveille-toi! Nous voulons nous excuser 
d’avoir abîmé ton Casse-Noisette, mais nous avons une 
solution ! Regardez ce bras musclé! - dit un des enfants de 
l’orphelinat alors que Clara se réveille de son rêve.

</p>
                    <p>- Est-il en plastique? - bâilla Clara, en s’étirant

</p>
                    <p>- Anh?... - dit le garçon.


</p>
                    <p>Clara sourit et conduit tous les enfants dans le jardin de 
l’orphelinat en passant derrière un buisson.

</p>
                    <p>- J’ai découvert que nous pouvons tous être des héros. Tout 
ce dont nous avons besoin est en nous et dans cette belle 
boîte. - cria joyeusement Clara.


</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter12"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/fim"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter13} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
