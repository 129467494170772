import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

//import ilustration from './../../assets/'

import chapter1 from './../../assets/chapter1.png'
import letterE from './../../assets/e.png'



class Story extends Component {
    constructor(){
        super()
        this.state={
            
        }

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_title'>
                    <h1>Quebra-nozes</h1>
                    <h2>El Reino de Babu</h2>
                </div>
                <div className='story_pg'>
                    <div className='firstparagraph'>
                        <img src={letterE} alt=""/>
                        <p className='firstp'>ra la Nochebuena y mientras la nieve cubría la ciudad 
con sus blancos mantos, una tierna niña esperaba a 
su querido padrino. Clara era una niña huérfana que 
vivía con otros niños en un viejo orfanato y todos los años 
recibían la visita de sus padrinos con regalos de Navidad.</p>
                    </div>
                    <p>Cuando sonó el timbre, todos los niños corrieron a la puerta 
esperando que fuera su padrino, pero era el padrino de 
Clara que había llegado, con una pequeña caja envuelta en 
papel rojo y un lazo verde. Clara sonrió y abrió rápidamente 
el regalo. ¡Era un precioso Cascanueces!</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter2"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter1} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
