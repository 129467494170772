import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

//import ilustration from './../../assets/'

import chapter1 from './../../assets/chapter1.png'
import letterE from './../../assets/c.png'



class Story extends Component {
    constructor(){
        super()
        this.state={
            
        }

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_title'>
                    <h1>Casse-Noisette</h1>
                    <h2>Le Royaume de Babu</h2>
                </div>
                <div className='story_pg'>
                    <div className='firstparagraph'>
                        <img src={letterE} alt=""/>
                        <p className='firstp'>
                        ’était la veille de Noël et tandis que la neige recouvrait 
la ville de ses vêtements blancs, une douce enfant 
attendait son parrain bien-aimé. Clara était une 
orpheline qui vivait avec d’autres enfants dans un vieil 
orphelinat et chaque année, ils recevaient la visite de leurs 
parrains avec des cadeaux de Noël.</p>
                    </div>
                    <p>Lorsque la cloche a sonné, tous les enfants ont couru vers 
la porte en espérant que ce soit leur parrain, mais c’était 
le parrain de Clara qui était arrivé, avec une petite boîte 
emballée dans du papier rouge et un nœud vert. Clara 
sourit et déballe rapidement le cadeau. C’est un magnifique 
Casse-Noisette !</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter2"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter1} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
