import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'

import chapter12 from './../../assets/chapter12.png'



class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Todos parecían confusos y entristecidos, hasta que apareció 
el Cascanueces.
</p>
                    <p>- Lo sabemos. Tu poder puede llegar a millones de personas. 
Lo único que tienes que hacer es compartirlo. - sonrió el 
Cascanueces.
</p>
                    <p>- En el Planeta Tierra, todos pueden ser héroes, sólo tengo 
que mostrar a las personas cómo pueden hacerlo. - dijo 
Clara, con esperanza en su voz
</p>
                    <p>Clara ha pedido a la Bailarina que gobierne el reino en su 
ausencia, y el Cascanueces se ha ofrecido a ayudarla en su 
misión por el Planeta Tierra.
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter11"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter13"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter12} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
