import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import chapter9 from './../../assets/chapter9.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Le Royaume de Babu était autrefois dirigé par la mère de 
Clara. Elle a utilisé le bambou pour créer ses deux plus 
grands alliés : la Ballerine et le Casse-Noisette. Lorsque la 
Fée des Bonbons est apparue avec ses soldats Gommes, 
elle a créé une armée de Casse-Noisettes pour défendre le 
royaume. Mais... ce n’était pas suffisant.
</p>
                    <p>La petite souris, qui était un animal pacifique et qui sculptait 
des brosses en bambou avec ses dents, a décidé de former 
une armée pour défendre le royaume.
</p>
                    <p>- Alors, je suis la princesse de ce royaume ? - demande Clara.
</p>
                    <p>- Non, mon amour, tu es la reine Clara - affirme la ballerine 
avec un sourire en passant sa main délicate sur le visage de 
Clara. Et elle continua à raconter l’histoire.
</p>
                    <p>“Quand les souris se rendirent compte que la reine, la 
mère de Clara, était très malade et que les casse-noisettes 
étaient plus faibles, elles proposèrent un plan, s’enfuir avec 
la princesse Clara, prendre la ballerine et le casse-noisette 
pour la protéger, jusqu’à ce qu’elle soit assez grande pour 
sauver le royaume.
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter8"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter10"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter9} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
