import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter8 from './../../assets/chapter8.png'





class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>A strong light briefly blinded the Nutcracker until he shouted:

</p>
                    <p>- Clara you are the princess! This is the Babu Kingdom and I 
am the last Nutcracker!
</p>
                    <p>Clara looks at him confused until her Ballerina and the rats 
appear to save them.
</p>
                    <p>- Hurry my dear, we must protect you from the Candy Fairy. - 
said the Ballerina as she held Clara’s hand.

</p>
                    <p>- Nutcracker! - shouted Clara, as she turned away from her 
faithful friend, who was fighting the huge army of jelly beans 
alongside the rats.

</p>
                    <p>In a place, safe and warm, the Ballerina explained to Clara 
her true story. The real name of the Green Kingdom was the 
Babu Kingdom and it had been created by her ancestors 
with the aim of caring for the happiness of people and planet 
earth through the fastest growing plant in the world, the 
bamboo.
</p>



                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter7"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter9"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter8} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
