import React from 'react'
import {BrowserRouter as Router, Link } from "react-router-dom";

class LangSwitch extends React.Component{
    constructor(props){
        super(props)
        this.state={
            openlang:true,
        }
        this.handleLang = this.handleLang.bind(this)
        this.handleClick = this.handleClick.bind(this)

    }
    componentDidMount(){
        setTimeout(()=> {
            let changestate= !this.state.openlang;
            this.setState({
                openlang: changestate
            })
        } , 5000 )


    }

    handleClick(){
        let changestate = !this.state.openlang;
        this.setState({
            openlang: changestate
        })
    }

    handleLang(lang, click){
        !this.props.ChangeLang || this.props.ChangeLang(lang,click)
        if (click)  console.log(lang);
        let changestate = !this.state.openlang;
        this.setState({
            openlang: changestate,
        })
    }
    render(){
        return(
        <div className="lang__button" >
            <div className="lang__icon" onClick={()=>this.handleClick()} >
            <i className="fas fa-globe-europe"></i>
            </div>
            <Router>
            <ul className={this.state.openlang ? "lang__list open": "lang__list"}>
                <li id="en" className={this.state.openlang ? "open": ""} onClick={()=>this.handleLang("en-GB", true)}>
                    <Link to="/">EN</Link>
                </li>

                <li id="es" className={this.state.openlang ? "open": ""} onClick={()=>this.handleLang("es-ES", true)}>
                    <Link to="/">ES</Link>
                </li>
                <li id="fr" className={this.state.openlang ? "open": ""} onClick={()=>this.handleLang("fr-FR", true)}>
                    <Link to="/">FR</Link>
                </li> 
                <li id="pt" className={this.state.openlang ? "open": ""} onClick={()=>this.handleLang("pt-PT", true)}>
                    <Link to="/">PT</Link>
                </li>
                
            </ul>
            </Router>
        </div>
        )
    }   
}

export default LangSwitch;
