import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import fimimg from './../../assets/fim.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>The children approached the box and found bamboo 
toothbrushes.</p>
                    <p>- Starting today, we will fight for the future of our planet and 
our greatest power will be the creativity we can do with it. - 
said Clara.
</p>
                    <p>- And when we have finished using the bamboo brushes 
what do we do with them? - asked a girl.
</p>
                    <p>- Creativity! I’m going to use one of the handles of these 
brushes to be the arm of my trusty Nutcracker. - explained 
Clara, smiling, as she put the bamboo handle on her 
Nutcracker
</p>
                    <p>- Little Miss Clara, where did you get all these toothbrushes? 
- asked the Orphanage Director
</p>
                    <p>Clara looked at her Nutcracker, smiled and answered the 
Director:
</p>
                    <p>- It’s the magic of Christmas!
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter13"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>

            </div>
        </article>

        <div className='ilustration'>
            <Link to="/story/chapter1"><img src={fimimg} alt=""/></Link>
        </div>
      </section>
    )
  }
}

export default Story
