import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter3 from './../../assets/chapter3.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Um enorme barulho acordou Clara!</p>
                    <p>Ao abrir os olhos, Clara ficou assustada com o exército de 
ratos que se encontrava na direção da sua bailarina, todos 
eles transportavam uma escova de dentes em bambu. De 
repente surge um soldado alto e valente que tentou impedir 
que os ratos levassem a bailarina, mas sem sucesso.</p>
                    <p>- Quebra-nozes?! – perguntou Clara, surpreendida - Devolvam a minha Bailarina! – gritou na direção dos ratos.</p>
                    <p>- Eu vou salvar-te! – gritou o Quebra-nozes enquanto corria para o buraco por onde os ratos escaparam.</p>
                    <p>Clara saltou da cama e correu atrás deles e, foi nesse momento que se apercebeu que tinha encolhido.</p>
                    <p>Ao atravessar o buraco, Clara chegou a um lugar lindo e cheio de canas verdes.</p>

                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter2"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter4"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter3} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
