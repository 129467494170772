import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'



import fimimg from './../../assets/fim.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Los niños se acercaron a la caja y descubrieron cepillos de 
dientes de bambú.
</p>
                    <p>- A partir de hoy, lucharemos por el futuro de nuestro planeta 
y nuestro mayor poder será la creatividad que podamos 
hacer con él. - dijo Clara.
</p>
                    <p>- Y cuando terminemos de usar los cepillos de bambú, ¿qué 
hacemos con ellos? - preguntó una niña.
</p>
                    <p>- ¡Creatividad! Voy a utilizar uno de los mangos de estos 
cepillos para que sea el brazo de mi leal Cascanueces. - 
explicó Clara, sonriendo, mientras ponía el cabo de bambú 
en su Cascanueces.
</p>
                    <p>- Señorita Clara, ¿de dónde has sacado todos estos cepillos 
de dientes? - preguntó la directora del orfanato.
</p>
                    <p>Clara miró su Cascanueces, sonrió y contestó a la directora:

</p>
                    <p>- ¡Es la magia de la Navidad!
</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter13"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>

            </div>
        </article>

        <div className='ilustration'>
            <Link to="/story/chapter1"><img src={fimimg} alt=""/></Link>
        </div>
      </section>
    )
  }
}

export default Story
