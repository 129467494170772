import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter2 from './../../assets/chapter2.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Curious and excited, all the children ran excitedly to Clara, 
grabbing the Nutcracker violently. Clara shouted at them 
to be careful, but it was too late, the Nutcracker’s arm was 
broken.</p>
                    <p>Seeing her only Christmas present destroyed, Clara 
grabbed it and in tears ran quickly to her room. Clara’s room 
was shared with 10 other girls and her bed was next to a 
large frosty window. When she got to her room Clara put 
the Nutcracker down on her bed and opened a music box, 
which was on her bedside table.</p>
                    <p>A soft melody began to play, while a beautiful pink ballerina 
twirled inside the box. This music box was very special to 
Clara, it was the last present her mother gave her before 
she died. Hearing the melody and with her eyes drenched in 
tears Clara lay down next to her Nutcracker and fell asleep.</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter1"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter3"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter2} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
