import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter10 from './../../assets/chapter10.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>All the Nutcrackers and also Clara’s mother were eliminated 
by the Candy Fairy who had been looking for the princess 
all these years. The Candy Fairy also managed to bewitch us 
by altering part of our memory.”

</p>
                    <p>- But, how can I save the Babu Kingdom? - Clara asked.

</p>
                    <p>- Everything you need is inside you. You have a unique 
power! - replied the ballerina in a sweet voice.

</p>
                    <p>Clara had an idea! She asked the mouses to help her build a 
huge yellow box. Meanwhile the Nutcracker lured the army 
of jelly beans, with plastic sticks, into the yellow box. As soon 
as the army passed through the yellow box the jelly beans 
turned into clouds and the plastic also took on a new life.

</p>




                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter9"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter11"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter10} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
