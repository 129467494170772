import React, { Component } from 'react'
import {Link} from 'react-router-dom'

import right from './../../assets/icons/left.png'
import home from './../../assets/icons/home.png'
import left from './../../assets/icons/right.png'


import chapter2 from './../../assets/chapter2.png'


class Story extends Component {
    constructor(){
        super()
        this.state={}

    }
  render() {
    return (
      <section className="story_sec"> 
        <article className='story'>
            <div className='story_text'>
                <div className='story_pg'>
                    <p>Curieux et excités, tous les enfants courent vers Clara, 
s’emparant violemment du Casse-Noisette. Clara leur cria 
de faire attention, mais il était trop tard, le bras de CasseNoisette était cassé..</p>
                    <p>Voyant son seul cadeau de Noël détruit, Clara le saisit et, en 
larmes, court rapidement vers sa chambre. La chambre de 
Clara était partagée avec 10 autres filles et son lit se trouvait 
à côté d’une grande fenêtre givrée. Lorsqu’elle arrive dans 
sa chambre, Clara pose le Casse-Noisette sur son lit et ouvre 
une boîte à musique qui se trouve sur sa table de nuit.</p>
                    <p>Une douce mélodie se mit à jouer, tandis qu’une belle 
ballerine rose virevoltait à l’intérieur de la boîte. Cette boîte 
à musique était très spéciale pour Clara, c’était le dernier 
cadeau que sa mère lui avait donné avant de mourir. En 
entendant la mélodie et avec ses yeux trempés de larmes, 
Clara s’est allongée à côté de son Casse-Noisette et s’est 
endormie.
</p>
                </div>
            </div>
            <div className='story_nav'>
                <Link to="/story/chapter1"><span><img src={right} alt=""/></span></Link>
                <Link to="/"><span className='home'><img src={home} alt=""/></span></Link>
                <Link to="/story/chapter3"><span><img src={left} alt=""/></span></Link>
            </div>
        </article>

        <div className='ilustration'>
            <img src={chapter2} alt=""/>
        </div>
      </section>
    )
  }
}

export default Story
